import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { color_colors_ocean, color_shades_dark, color_variants_ocean_light_2 } from '../../../constants/colors'
import TextField from '../../../components/forms/TextField'
import MenuContainer from '../../../hooks/MenuContainer'
import { css } from '@emotion/css'
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection'
import CheckboxField from '../../../components/forms/CheckboxField'
import SynonymsEditor from '../../interactionModel/components/SynonymsEditor'
import ContentCard, { NoMarginContentCard } from '../../../components/structure/ContentCard'
import CollapsableItem from './CollapsableItem'
import { CardContainer } from '../../applicationSettings/components/SharedStyledComponents'
import { MenuItemOptionGroup } from '../../../models/nlp/menu/MenuItemOptionGroup'
import { getExtraOptionGroupText } from './MenuItemEditor'
import PersistentPropertyTitle from './PersistentPropertyTitle'
import StyledDropdown from '../../applicationSettings/components/customAssistantConfigurations/StyledDropdown'
import Button from '../../../components/general/Button'
import { MenuItemOption } from '../../../models/nlp/menu/MenuItemOption'
import MenuItemModel from '../../../models/nlp/menu/MenuItemModel'

const listImage = require("../../../content/images/menu-editor/list.svg");
const inactiveListImage = require("../../../content/images/menu-editor/inactive-list.svg");
const synonymsIcon = require('../../../content/images/synonyms.svg');
const settingsIcon = require('../../../content/images/tab-icons/settings-dark-icon.svg');
const menuItemIcon = require("../../../content/images/menu-editor/restaurant-menu.svg");

export default function MenuItemOptionEditor() {
    const menuContainer = MenuContainer.useContainer();
    const lastItem = menuContainer.path[menuContainer.path.length - 1];
    const [selectedOptionGroupToMoveOptionTo, setSelectedOptionGroupToMoveOptionTo] = React.useState<string | null>(null);
    const [isCopyingOption, setIsCopyingOption] = useState(false);

    useEffect(() => {
        setSelectedOptionGroupToMoveOptionTo(null);
    }, [])

    const advancedSettings = [
        {
            text: "Don't read this option aloud",
            tooltip: `Don't have the assistant read ${lastItem?.selectedOption?.name || 'this option'} aloud when providing options to the customer.`,
            tooltipStyle: checkboxTooltipStyle,
            isCheckBox: true,
            isChecked: lastItem?.selectedOption?.ignoreForReadout,
            onChange: (e) => {
                lastItem.selectedOption.ignoreForReadout = e.target.checked;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "ignoreForReadout");
            }
        },
        {
            text: "Is this option the primary option?",
            tooltip: `This sets the ${lastItem?.selectedOption?.name || 'option'} as the primary option for this option group.`,
            tooltipStyle: checkboxTooltipStyle,
            isCheckBox: true,
            isChecked: lastItem?.selectedOption?.isPrimaryOption,
            onChange: (e) => {
                lastItem.selectedOption.isPrimaryOption = e.target.checked;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "isPrimaryOption");
            }
        },
        {
            text: "Is this option the default option?",
            tooltip: `This sets the ${lastItem?.selectedOption?.name || 'option'} as the default option for this option group.`,
            tooltipStyle: checkboxTooltipStyle,
            isCheckBox: true,
            isChecked: lastItem?.selectedOption?.default,
            onChange: (e) => {
                lastItem.selectedOption.default = e.target.checked;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "default");
            }
        },
        {
            text: "Is this option the fixed option?",
            tooltip: `This sets the ${lastItem?.selectedOption?.name || 'option'} as the fixed option for this option group.`,
            tooltipStyle: checkboxTooltipStyle,
            isCheckBox: true,
            isChecked: lastItem?.selectedOption?.fixed,
            onChange: (e) => {
                lastItem.selectedOption.fixed = e.target.checked;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "fixed");
            }
        },
        {
            text: "Minimum Number of Options Allowed",
            tooltip: `This sets the minimum quantity of ${lastItem?.selectedOption?.name || 'options'} a customer can order with the ${menuContainer.menuItem?.originalName} menu item. Set to 0 if there is no minimum.`,
            tooltipStyle: tooltipStyle,
            value: lastItem?.selectedOption?.minQuantity,
            onChange: (e) => {
                lastItem.selectedOption.minQuantity = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "minQuantity");
            }
        },
        {
            text: "Maximum Number of Options Allowed",
            tooltip: `This sets the maximum quantity of ${lastItem?.selectedOption?.name || 'options'} a customer can order with the ${menuContainer.menuItem?.originalName} menu item. Set to 0 if there is no maximum.`,
            tooltipStyle: tooltipStyle,
            value: lastItem?.selectedOption?.maxQuantity,
            onChange: (e) => {
                lastItem.selectedOption.maxQuantity = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "maxQuantity");
            }
        }
    ]

    const activeOptionGroups = lastItem.selectedOption?.optionGroups?.filter(optionGroup => !optionGroup.isDisabled) ?? [];
    const inactiveOptionGroups = lastItem.selectedOption?.optionGroups?.filter(optionGroup => optionGroup.isDisabled) ?? [];

    function renderOptionGroups(optionGroups: MenuItemOptionGroup[]) {
        return optionGroups.map((optionGroupObj, index) => {
            return (
                <div key={`optionGroup.${index}`}>
                    <OptionGroupWrapper
                        className={optionGroupObj.referenceId === menuContainer.activeEditId ? "active" : ""}
                        key={optionGroupObj.editId}
                        onClick={() => {
                            const path = {
                                text: optionGroupObj.name,
                                pathLength: menuContainer.path?.length ?? 0,
                                selectedOption: null,
                                selectedOptionGroup: optionGroupObj,
                                selectedSizeGroup: null,
                                selectedSize: null,
                            };
                            menuContainer.setPath([...menuContainer.path, path])
                        }}
                    >
                        <CardContainer
                        >
                            <ListItemLeftWrapper>
                                <CollapsableItem
                                    text={optionGroupObj.name}
                                    isDisabled={optionGroupObj.isDisabled}
                                    italicText={getExtraOptionGroupText(!!(optionGroupObj.promptIfNoSelections || optionGroupObj.numberOfRequiredOptions), optionGroups)}
                                    onEnabledChange={(enabled) => {
                                        optionGroupObj.isDisabled = !enabled;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, optionGroupObj, "isDisabled");
                                    }}
                                />
                            </ListItemLeftWrapper>
                        </CardContainer>
                    </OptionGroupWrapper>
                </div>
            )
        })
    }

    function handleCopyOption() {
        setIsCopyingOption(true);
        setTimeout(() => {
            const targetOptionGroup = menuContainer.menuItem?.optionGroups.find(og => og.referenceId === selectedOptionGroupToMoveOptionTo);
            if (targetOptionGroup) {
                //make sure the option group doesnt have this option
                if (targetOptionGroup.options.find(o => o.name === lastItem.selectedOption.name)) {
                    setIsCopyingOption(false);
                    return;
                }

                const optionToBeCopied: MenuItemOption = JSON.parse(JSON.stringify(lastItem.selectedOption));
                //find the parent option group
                for (const path of menuContainer.path.reverse()) {//need to find the first option group added prior to this option
                    if (path.selectedOptionGroup) {
                        optionToBeCopied.parentOptionGroupReferenceId = path.selectedOptionGroup.referenceId;
                        break;
                    }
                }

                targetOptionGroup.options.push(optionToBeCopied);
                menuContainer.updateMenuItem(menuContainer.menuItem, targetOptionGroup, "");
            }
            setIsCopyingOption(false);
        }, 300);
    }

    const getAllOptionGroups = (menuItem: MenuItemModel | MenuItemOption): MenuItemOptionGroup[] => {
        let allGroups: MenuItemOptionGroup[] = [];

        // Add top level option groups
        if (Array.isArray(menuItem?.optionGroups)) {
            allGroups = allGroups.concat(menuItem.optionGroups);

            // Recursively get option groups from each option group's options
            menuItem.optionGroups.forEach(group => {
                if (Array.isArray(group.options)) {
                    group.options.forEach(option => {
                        if (Array.isArray(option.optionGroups)) {
                            allGroups = allGroups.concat(getAllOptionGroups(option));
                        }
                    });
                }
            });
        }

        return allGroups;
    };

    const allOptionGroups = getAllOptionGroups(menuContainer.menuItem);

    return (
        <MenuItemOptionWrapper>
            {lastItem?.selectedOption ?
                <>
                    <ContentCollapsingSection
                        title={"Details"}
                        className={firstContentCollapsingSectionStyle}
                        isCollapsedByDefault={false}
                        icon={menuItemIcon}
                    >
                        <StyledContentCard
                            id="optionDetails" >
                            <TextField
                                name="Colloquial Name"
                                useForm={false}
                                disabled={false}
                                value={lastItem.selectedOption.colloquialName ?? ""}
                                label={
                                    <PersistentPropertyTitle
                                        title="Option Referred to As"
                                        item={lastItem.selectedOption}
                                        propertyName='colloquialName'
                                    />
                                }
                                placeholder={`${`Enter a new name for ${lastItem.selectedOption.name} or leave blank to use the option's original name`}`}
                                tooltip={`This is the name the assistant will use to refer to ${lastItem?.selectedOption?.name || 'this option'}.`}
                                tooltipStyle={tooltipStyle}
                                onChange={(e) => {
                                    lastItem.selectedOption.colloquialName = e.target.value;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "colloquialName");
                                }}
                                type="string"
                            />

                            <TextField
                                name="Option Price"
                                useForm={false}
                                disabled={false}
                                value={lastItem.selectedOption?.attributes?.data?.price ?? ""}
                                label={
                                    <PersistentPropertyTitle
                                        title='Option Price'
                                        item={lastItem?.selectedOption?.attributes}
                                        propertyName='data'
                                    />
                                }
                                tooltip='This should be the additional cost for ordering this option.'
                                tooltipStyle={tooltipStyle}
                                placeholder="Price of the option"
                                onChange={(e) => {
                                    if (!lastItem.selectedOption?.attributes?.data) lastItem.selectedOption.attributes.data = {};
                                    lastItem.selectedOption.attributes.data.price = e.target.value;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem?.selectedOption?.attributes?.data, "price", { changedObj: lastItem?.selectedOption?.attributes, changedProperty: "data" });
                                }}
                                type="string"
                            />
                        </StyledContentCard>
                    </ContentCollapsingSection>
                    <>
                        <ContentCollapsingSection
                            title={"Synonyms"}
                            isCollapsedByDefault={true}
                            icon={synonymsIcon}
                        >
                            <NoMarginContentCard
                                title="Add Synonyms"
                                subtitle={`Add other names or ways customers might refer to ${lastItem.selectedOption.name ?? "this item"}.`}
                                id="optionSynonyms" >
                                <SynonymsEditor
                                    className={SynonymsClass}
                                    synonyms={lastItem.selectedOption?.additionalSynonyms}
                                    synonymsFieldName={'optionSynonyms'}
                                    disableFields={false}
                                    handleDelete={function (index: number, arrayHelpers: any) {
                                        let newSynonyms = lastItem.selectedOption?.additionalSynonyms?.filter((synonym, i) => i !== index);
                                        if (!newSynonyms?.length)
                                            newSynonyms = [];
                                        lastItem.selectedOption.additionalSynonyms = newSynonyms;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "additionalSynonyms");
                                    }}
                                    handleAddSynonym={function (synonym: string): void {
                                        if (!lastItem?.selectedOption?.additionalSynonyms?.length) lastItem.selectedOption.additionalSynonyms = [];
                                        lastItem.selectedOption.additionalSynonyms.push(synonym);
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOption, "additionalSynonyms");
                                    }}
                                    handleBlur={() => { }}
                                    autofocusNewSynonym={false}
                                />
                            </NoMarginContentCard>
                        </ContentCollapsingSection>
                        {!lastItem.selectedOption?.optionGroups?.length ? <></> :
                            <ContentCollapsingSection
                                title={"Option Groups"}
                                isCollapsedByDefault={false}
                            >
                                <ContentCard
                                    title={`Option Groups for ${lastItem.selectedOption.name}`}
                                    icon={listImage}
                                    id="optionSynonyms"
                                >
                                    {activeOptionGroups?.length > 0
                                        ?
                                        renderOptionGroups(activeOptionGroups)
                                        :
                                        <NoContentAvailable>No active option group available</NoContentAvailable>
                                    }
                                </ContentCard>
                                <NoMarginContentCard
                                    title={`Edit Inactive Option Groups`}
                                    icon={inactiveListImage}
                                    id="optionSynonyms"
                                >
                                    {inactiveOptionGroups?.length > 0
                                        ?
                                        renderOptionGroups(inactiveOptionGroups)
                                        :
                                        <NoContentAvailable>No inactive option group available</NoContentAvailable>
                                    }
                                </NoMarginContentCard>
                            </ContentCollapsingSection>
                        }
                        <ContentCollapsingSection
                            title={"Advanced Settings (Admin Only)"}
                            icon={settingsIcon}
                            isCollapsedByDefault={true}
                            bottom
                            scrollDownOnClick={true}
                        >
                            <NoMarginContentCard
                                title='Edit Option Advanced Settings'
                                subtitle='These settings are for administrators only. Please consult with your technical team before making changes.'
                                id="optionAdvancedSettings"
                            >
                                <AdvancedSettingsContainer>
                                    {advancedSettings?.map((setting, index) => {
                                        if (setting.isCheckBox) {
                                            return (
                                                <CheckboxField
                                                    key={`setting.${index}`}
                                                    tooltip={setting.tooltip}
                                                    tooltipStyle={setting.tooltipStyle}
                                                    checked={setting.isChecked ? true : false}
                                                    onChange={setting.onChange}
                                                    label={setting.text}
                                                />
                                            )
                                        }
                                        return (
                                            <TextField
                                                key={`setting.${index}`}
                                                tooltip={setting.tooltip}
                                                tooltipStyle={setting.tooltipStyle}
                                                useForm={false}
                                                disabled={false}
                                                value={setting.value}
                                                label={setting.text}
                                                placeholder="0"
                                                onChange={setting.onChange}
                                                type={typeof setting.value}
                                            />
                                        )
                                    })}
                                    <div
                                        style={{
                                            "display": "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ "flex": "1" }}>
                                            <StyledDropdown
                                                value={selectedOptionGroupToMoveOptionTo ?? ""}
                                                handleOnChange={(fieldName: string, value: string) => setSelectedOptionGroupToMoveOptionTo(value)}
                                                fieldName="copy"
                                                options={allOptionGroups.map(optionGroup => ({ label: optionGroup.name, value: optionGroup.referenceId }))}
                                                size="medium"
                                                tooltip='This is the category the assistant will use to categorize this item.'
                                                label="Copy to"
                                                placeholder="NA"
                                            />
                                        </div>
                                        <Button
                                            themes={["primary"]}
                                            text="Copy"
                                            type="button"
                                            loading={isCopyingOption}
                                            disabled={!selectedOptionGroupToMoveOptionTo}
                                            className={CopyToButton}
                                            onClick={handleCopyOption}
                                        />
                                    </div>
                                </AdvancedSettingsContainer>
                            </NoMarginContentCard>
                        </ContentCollapsingSection>
                    </>
                </>
                :
                <></>
            }
        </MenuItemOptionWrapper >
    )
}

const firstContentCollapsingSectionStyle = css`
    border-top: none;
`;

const checkboxTooltipStyle = css`
    margin: 0 0 24px 8px;
`;

const tooltipStyle = css`
    margin-left: 8px;
`;

const AdvancedSettingsContainer = styled.div`
    margin-top: 16px;
`;

const MenuItemOptionWrapper = styled.div`
    height: 100%;
`

const NoContentAvailable = styled.div`
    padding: 10px;
`;

const OptionGroupWrapper = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;

const SynonymsClass = css`
    margin-top: 16px;
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledContentCard = styled(ContentCard)`
    margin: 0;
    padding-bottom: 8px;
`;

const CopyToButton = css`
    font-size: 14px;
    box-shadow: none;
    height: 34px;
    margin-top: 44px;
`;