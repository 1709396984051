import { withFormik } from 'formik';
import * as yup from 'yup';
import SparkTelephonyDeployment from './SparkTelephonyDeployment';
import TelephonyDeploymentRequest from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentRequest';
import { defaultTelephonyDeploymentSettings } from '../../../../shared/constants/telephonyConstants.tsx/defaultTelephonyDeploymentSettings';
import { AppendedSection } from './ApplicationManagerContent';

export interface SparkTelephonyDeploymentProps {
    voiceOptions: any
    handlePreviousSection: () => void
    handleNextSection: () => void
    createTelephonyDeployment: (appId: string, telephonyDeploymentRequest: TelephonyDeploymentRequest) => Promise<any>
    applicationId: string
    environmentId: string
    appendedSections: AppendedSection[]
    telephonyDeploymentSection: AppendedSection
    setAppendedSections: (sections: AppendedSection[]) => void
    incompleteSections: any[]
    setIncompleteSections: (sections: any[]) => void
    getVoiceString: (voice: string) => string
    clearTelephonyDeploymentErrors: () => void
    setPageErrorText: (text: string) => void
    phoneNumberDeploymentErrorText: string
    setPhoneNumberDeploymentErrorText: (text: string) => void
    getTabs: (existingTelephonyDeployment?: boolean) => void
    setTabs: (tabs: any) => void
    loadTelephonyDeployments: () => void
    handleRemoveSection: (sectionToRemove: AppendedSection) => void
    removeSectionFromIncompleteSections: (sectionToRemove: AppendedSection) => void
}

export interface SparkTelephonyDeploymentFormData {
    phoneNumberStartsWith: string
    callRecordingEnabled: boolean
    ttsVoice: string
    useVirtualNumber: boolean
    virtualNumber?: string
}

const SparkTelephonyDeploymentForm = withFormik<SparkTelephonyDeploymentProps, SparkTelephonyDeploymentFormData>({
    mapPropsToValues: props => (
        {
            phoneNumberStartsWith: '',
            callRecordingEnabled: false,
            ttsVoice: 'female1',
            useVirtualNumber: false,
            virtualNumber: ''
        }
    ),
    validationSchema: yup.object().shape({}),
    handleSubmit: async (values, { props }) => {

        const telephonyDeploymentRequest: TelephonyDeploymentRequest = {
            environmentId: props.environmentId,
            noTracking: defaultTelephonyDeploymentSettings.noTracking,
            phoneNumberStartsWith: values.useVirtualNumber ? null : (values.phoneNumberStartsWith ?? defaultTelephonyDeploymentSettings.phoneNumberStartsWith),
            ttsVoice: props.getVoiceString(values.ttsVoice) ?? defaultTelephonyDeploymentSettings.ttsVoice,
            locale: defaultTelephonyDeploymentSettings.locale,
            asrEndpointing: defaultTelephonyDeploymentSettings.asrEndpointing,
            asrProvider: defaultTelephonyDeploymentSettings.asrProvider,
            asrModel: defaultTelephonyDeploymentSettings.asrModel,
            asrTier: defaultTelephonyDeploymentSettings.asrTier,
            ttsPronunciationsMap: defaultTelephonyDeploymentSettings.ttsPronunciationsMap,
            firstMessageDelayMS: defaultTelephonyDeploymentSettings.firstMessageDelayMS,
            mergeTurnsAfterPauses: defaultTelephonyDeploymentSettings.mergeTurnsAfterPauses,
            mergeTurnTimeoutMS: defaultTelephonyDeploymentSettings.mergeTurnTimeoutMS,
            disableBargeIn: defaultTelephonyDeploymentSettings.disableBargeIn,
            repromptAttempts: defaultTelephonyDeploymentSettings.repromptAttempts,
            repromptDelaySeconds: defaultTelephonyDeploymentSettings.repromptDelaySeconds,
            name: defaultTelephonyDeploymentSettings.name,
            ttsProvider: defaultTelephonyDeploymentSettings.ttsProvider,
            proactiveResponseDelayMS: defaultTelephonyDeploymentSettings.proactiveResponseDelayMS,
            proactiveResponses: defaultTelephonyDeploymentSettings.proactiveResponses,
            useProactiveResponses: defaultTelephonyDeploymentSettings.useProactiveResponses,
            disableWelcomeMessageBargeIn: defaultTelephonyDeploymentSettings.disableWelcomeMessageBargeIn,
            disableSpeechStarted: defaultTelephonyDeploymentSettings.disableSpeechStarted,
            speechStartedUnconfirmedBargeInLimit: defaultTelephonyDeploymentSettings.speechStartedUnconfirmedBargeInLimit,
            speechStartedPauseMS: defaultTelephonyDeploymentSettings.speechStartedPauseMS,
            speechStartedRequiredBufferCount: defaultTelephonyDeploymentSettings.speechStartedRequiredBufferCount,
            callRecordingEnabled: values.callRecordingEnabled ?? defaultTelephonyDeploymentSettings.callRecordingEnabled,
            speechStartedLevel: defaultTelephonyDeploymentSettings.speechStartedLevel,
            virtualNumber: values.useVirtualNumber && defaultTelephonyDeploymentSettings.virtualNumber,
            useVirtualNumber: values.useVirtualNumber
        };

        const createTelephonyDeploymentResponse = await props.createTelephonyDeployment(props.applicationId, telephonyDeploymentRequest);

        if (createTelephonyDeploymentResponse && !createTelephonyDeploymentResponse.errors.length) {

            const updatedTabs = props.getTabs(true);

            props.setTabs(updatedTabs);

            const copyAppendedSections = [...props.appendedSections];

            const updatedSections = copyAppendedSections.filter(section => section.id !== props.telephonyDeploymentSection.id);

            props.setAppendedSections(updatedSections);
        };

        if (createTelephonyDeploymentResponse.errors.length > 0) {
            const incompleteTelephonyDeploymentSection = { ...props.telephonyDeploymentSection, isComplete: false };
            if (createTelephonyDeploymentResponse.errors[0] === "No numbers found") {
                props.setPageErrorText("Uh oh! It looks like the area code you chose isn't available. Please try again with a different one.");
            } else {
                props.setPageErrorText("Uh oh! It looks like there was a problem creating your phone number. Please try again in a few minutes.");
            }
            const telephonyDeploymentSectionIndex = props.incompleteSections?.findIndex((section) => {
                return section.id === incompleteTelephonyDeploymentSection.id
            });
            const incompleteSectionsCopy = [...props.incompleteSections];
            if (telephonyDeploymentSectionIndex !== -1) {
                incompleteSectionsCopy[telephonyDeploymentSectionIndex] = incompleteTelephonyDeploymentSection;
                props.setIncompleteSections(incompleteSectionsCopy);
            } else {
                props.setIncompleteSections([...incompleteSectionsCopy, incompleteTelephonyDeploymentSection]);
            }
        }
    }
})(SparkTelephonyDeployment);


export default SparkTelephonyDeploymentForm;