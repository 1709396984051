import styled from '@emotion/styled';
import React from 'react';
import { color_colors_ocean, color_shades_darker } from '../../../../../shared/constants/colors';
import { css } from '@emotion/css';
import ApplicationItem from './ApplicationItem';
import HorizontalContainer from '../../../../../shared/components/structure/HorizontalContainer';
import SearchField from '../../../../../shared/components/forms/SearchField';
import { ApplicationModelWithPhoneNumberAndStatus } from './ApplicationDashboard';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import ApplicationAnalyticsFacetContainer from '../../../../../shared/state/containers/ApplicationAnalyticsFacetContainer';
import { style_border_default } from '../../../../../shared/constants/stylesValues';
import CustomScrollbars from '../../../../../shared/components/structure/CustomScrollbars';
import SecondaryLoader from '../../../../../shared/components/general/SecondaryLoader';
import { breakpoint_small } from '../../../../../shared/constants/breakpoints';

const emptyImage = require('../../../../../shared/content/images/content-explorer/empty.svg');

interface LocationsListProps {
    appsContainer: ApplicationContainer
    orgContainer: OrganizationContainer
    organizationId: string
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    facetContainer: ApplicationAnalyticsFacetContainer
    history: any
    commonApps: ApplicationModelWithPhoneNumberAndStatus[]
    locationApps: ApplicationModelWithPhoneNumberAndStatus[]
    isLoadingApps: boolean
    search: string
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleSearchKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
    tooltip?: string
    isLoadingIncompleteFields?: boolean
}

const LocationsList: React.FC<LocationsListProps> = (props) => {

    return (
        <LocationsListOutterWrapper>
            {(!props.commonApps && !props.locationApps) || props.isLoadingIncompleteFields ? <CustomLoader />
                :
                <Wrapper>
                    {props.commonApps?.length > 0 || props.locationApps?.length > 1 &&
                        <HorizontalContainer className={searchAndModify}>
                            <SearchField
                                id="1"
                                name={"searchField"}
                                placeholder={"Search for a location"}
                                onChange={props.handleSearch}
                                onKeyDown={props.handleSearchKeyDown}
                                value={props.search}
                                className={searchField}
                            />
                        </HorizontalContainer>
                    }
                    <CustomScrollbars
                        autoHide
                        autoHeight
                        autoHeightMax="calc(100vh - 340px)"
                    >
                        {
                            (props.commonApps?.length > 0 || props.locationApps?.length > 0 ? (
                                <>
                                    {props.commonApps.filter(app => app.name.toLowerCase().includes(props.search.toLowerCase())).map((app) => (
                                        <CommonAppsWrapper key={app.id}>
                                            <ApplicationItem
                                                organizationId={props.organizationId}
                                                app={app}
                                                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                                                facetContainer={props.facetContainer}
                                                history={props.history}
                                                applicationType='common'
                                                tooltip={props.tooltip}
                                            />
                                        </CommonAppsWrapper>
                                    ))}
                                    {props.locationApps.filter(app => app.name.toLowerCase().includes(props.search.toLowerCase())).map((app) => (
                                        <LocationAppsWrapper key={app.id}>
                                            <ApplicationItem
                                                organizationId={props.organizationId}
                                                app={app}
                                                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                                                facetContainer={props.facetContainer}
                                                history={props.history}
                                                applicationType='location'
                                                disabled={props.commonApps.some(commonApp => commonApp.hasIncompleteFormFields === true)}
                                            />
                                        </LocationAppsWrapper>
                                    ))}
                                </>
                            ) : (
                                <EmptyWrapper>
                                    <NoAppsDescription>Looks like you don't have any locations with AI Answering enabled.</NoAppsDescription>
                                </EmptyWrapper>
                            ))
                        }
                    </CustomScrollbars>
                </Wrapper>
            }
        </LocationsListOutterWrapper>
    )
}

const LocationsListOutterWrapper = styled.div`
    margin: 0 0 24px 0;
    position: relative;
    overflow: hidden;
    flex: 1;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CommonAppsWrapper = styled.div`
    margin: 0 24px;
    &:first-of-type {
        border-top: ${style_border_default};
        border-radius: 8px;
        margin-top: 8px;
        margin-bottom: 16px;
    }
    ${breakpoint_small} {
        margin: 0 8px;
    }
`;

const LocationAppsWrapper = styled.div`
    margin: 0 24px;
    &:first-of-type {
        border-top: ${style_border_default};
        border-radius: 8px;
        margin-top: 8px;
    }
    ${breakpoint_small} {
        margin: 0 8px;
    }
`;

const EmptyWrapper = styled.div`
    margin: 32px;
    padding: 32px 0;
    background-image: url(${emptyImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoAppsDescription = styled.div`
    font-size: 14px;
    color: ${color_shades_darker};
    margin-top: 48px;
`;

const CustomLoader = styled(SecondaryLoader)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .spinner > div {
        background: ${color_colors_ocean};
    }
`;

const searchField = css`
    margin-bottom: 0px;
`;

const searchAndModify = css`
    margin: 8px 24px 12px 24px;
    padding: 0;
    flex: 1;
    div {        
        box-shadow: none;
    }
    .number-container {
        box-shadow: none;
    }
    .field-container {
        height: 56px;
        border-radius: 16px;
        margin-top: 0px;
    }
    .table-grid {
        margin-left: 16px;
    }   
`;

export default LocationsList;