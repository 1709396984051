import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TabPanel } from 'react-tabs';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import UserContainer from '../../../../../shared/state/containers/UserContainer';
import MemberContainer from '../../../../../shared/state/containers/MemberContainer';
import TabContainerPanel from '../../../../../shared/components/structure/Panels/TabContainerPanel';
import ScrollablePanelBody from '../../../../../shared/components/structure/ScrollablePanelBody';
import OrganizationInfoSettings from '../../../../../shared/scenes/organizationSettings/components/OrganizationInfoSettings';
import OrganizationIntegrationsSettings from '../../../../../shared/scenes/organizationSettings/components/OrganizationIntegrationsSettings';
import OrganizationApplications from './OrganizationApplications';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import TemplateFormSectionModel from '../../../../../shared/models/templating/api/TemplateFormSectionModel';
import TemplateFormFieldModel from '../../../../../shared/models/templating/api/TemplateFormFieldModel';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import { ImageTabItem } from '../../../../../shared/components/structure/ImageInnerTabs';
import TemplateConfigurationContainer from '../../../../../shared/state/containers/TemplateConfigurationContainer';
import ConfirmationDialog from '../../../../../shared/components/structure/ConfirmationDialog';
import styled from '@emotion/styled';
import { authenticateJobber, authenticateSalesforce } from '../../../../../shared/api/controllers/businessManagement';

const basicInfoBlue = require('../../../../../shared/content/images/organization-settings/basic-info-blue.svg');
const basicInfoDark = require('../../../../../shared/content/images/organization-settings/basic-info-dark.svg');
const locationsBlue = require("../../../../../shared/content/images/spark/locations-blue.svg");
const locationsDark = require("../../../../../shared/content/images/spark/locations-dark.svg");
const integrationsBlue = require('../../../../../shared/content/images/organization-settings/integrations-tab-blue.svg');
const integrationsDark = require('../../../../../shared/content/images/organization-settings/integrations-tab-dark.svg');

interface OrganizationSettingsProps {
    appContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    userContainer: UserContainer;
    memberContainer: MemberContainer;
    commonApp: ApplicationModel;
    locationApps?: ApplicationModel[];
    locationAppNames: string[];
    commonAppSections: TemplateFormSectionModel[];
    locationAppFormFields: TemplateFormFieldModel[];
    isLoadingTemplates: boolean;
    history: any;
    hideApplicationSettings?: boolean;
    padding?: string;
    templateConfigurationContainer: TemplateConfigurationContainer;
}

const OrganizationSettingsTabs: React.FC<OrganizationSettingsProps> = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        props.userContainer.getUser();
        props.memberContainer.checkIsOrgAdmin(props.orgContainer.state.currentOrganization?.id, props.userContainer.state.currentUser?.id);

        // Check for Jobber auth code
        handleIntegrationAuthenticationRedirect();

    }, []);

    const handleIntegrationAuthenticationRedirect = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const authorizationCode = urlParams.get('code');
            const state = urlParams.get('state');

            if (!authorizationCode || !state) {
                return;
            }
            const integrationType = state.split('-')[0];

            const applicationId = state.split('-').slice(1).join('-');;

            const handleJobberAuthentication = async (authorizationCode: string, applicationId: string) => {
                const result = await authenticateJobber({
                    applicationId: applicationId,
                    authorizationCode
                });
                if (result.resultType === "Ok" && result.data) {
                    setShowSuccessModal(true);
                    //remove the code and state from the url
                    history.replace(window.location.pathname);
                } else {
                    setShowErrorModal(true);
                }
            }

            const handleSalesforceAuthentication = async (authorizationCode: string) => {
                //The authorization code ends with %3D%3D, we need to change that to ==
                authorizationCode = authorizationCode.replace("%3D%3D", "==");
                //state looks like Salesforce-${selectedLocation.salesforce}-id-${clientId}-secret-${salesforceCredentials.clientSecret}
                const stateString = state;

                const applicationId = stateString.substring(
                    stateString.indexOf('Salesforce-') + 'Salesforce-'.length,
                    stateString.indexOf('-id-')
                );

                const clientId = stateString.substring(
                    stateString.indexOf('-id-') + '-id-'.length,
                    stateString.indexOf('-secret-')
                );
                const clientSecret = stateString.substring(
                    stateString.indexOf('-secret-') + '-secret-'.length
                );

                const result = await authenticateSalesforce({
                    applicationId: applicationId,
                    authorizationCode,
                    clientId,
                    clientSecret
                });
                if (result.resultType === "Ok" && result.data) {
                    setShowSuccessModal(true);
                    //remove the code and state from the url
                    history.replace(window.location.pathname);
                } else {
                    setShowErrorModal(true);
                }
            }

            if (integrationType === "Jobber") {
                handleJobberAuthentication(authorizationCode, applicationId);
            } else if (integrationType === "Salesforce") {
                handleSalesforceAuthentication(authorizationCode);
            }

        } catch (error) {
            console.error('Error authenticating Jobber:', error);
            setShowErrorModal(true);
        }
    };

    const handleTabSelection = (index: number, last: number) => {
        if (index === last) return;
        setActiveIndex(index);
    };

    const renderTabs = () => {
        if (props.hideApplicationSettings) {
            return [
                {
                    title: 'Basic Info',
                    icon: basicInfoDark,
                    selectedIcon: basicInfoBlue
                },
                {
                    title: 'Integrations',
                    icon: integrationsDark,
                    selectedIcon: integrationsBlue
                }
            ];
        }

        return [
            {
                title: 'Basic Info',
                icon: basicInfoDark,
                selectedIcon: basicInfoBlue
            },
            {
                title: 'Locations',
                icon: locationsDark,
                selectedIcon: locationsBlue
            },
            {
                title: 'Integrations',
                icon: integrationsDark,
                selectedIcon: integrationsBlue
            }
        ];
    };

    return (
        <>
            <TabContainerPanel
                selectedIndex={activeIndex}
                onTabSelected={handleTabSelection}
                tabs={renderTabs()}
            >
                {props.hideApplicationSettings ? (
                    <>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationInfoSettings
                                    orgContainer={props.orgContainer}
                                    templateConfigurationContainer={props.templateConfigurationContainer}
                                    commonApp={props.commonApp}
                                    appContainer={props.appContainer}
                                />
                            </ScrollablePanelBody>
                        </TabPanel>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationIntegrationsSettings organizationId={props.orgContainer.state.currentOrganization?.id} />
                            </ScrollablePanelBody>
                        </TabPanel>
                    </>
                ) : (
                    <>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationInfoSettings
                                    orgContainer={props.orgContainer}
                                    templateConfigurationContainer={props.templateConfigurationContainer}
                                    commonApp={props.commonApp}
                                    appContainer={props.appContainer}
                                />
                            </ScrollablePanelBody>
                        </TabPanel>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationApplications
                                    appContainer={props.appContainer}
                                    orgContainer={props.orgContainer}
                                    commonApp={props.commonApp}
                                    locationApps={props.locationApps}
                                    locationAppNames={props.locationAppNames}
                                    commonAppSections={props.commonAppSections}
                                    locationAppFormFields={props.locationAppFormFields}
                                    isLoadingTemplates={props.isLoadingTemplates}
                                    history={props.history}
                                />
                            </ScrollablePanelBody>
                        </TabPanel>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationIntegrationsSettings organizationId={props.orgContainer.state.currentOrganization?.id} />
                            </ScrollablePanelBody>
                        </TabPanel>
                    </>
                )}
            </TabContainerPanel>

            {showSuccessModal && (
                <ConfirmationDialog
                    title="Account Connected Successfully"
                    onClose={() => setShowSuccessModal(false)}
                    onConfirm={() => setShowSuccessModal(false)}
                    deleteText="OK"
                >
                    <JobberSuccessText>
                        <p>Your account has been successfully connected. Your assistant can now access client and customer information.</p>
                    </JobberSuccessText>
                </ConfirmationDialog>
            )}

            {showErrorModal && (
                <ConfirmationDialog
                    title="Account Connection Failed"
                    onClose={() => setShowErrorModal(false)}
                    onConfirm={() => setShowErrorModal(false)}
                    deleteText="OK"
                >
                    <JobberSuccessText>
                        <p>There was an error connecting your account. Please try again or contact support if the issue persists.</p>
                    </JobberSuccessText>
                </ConfirmationDialog>
            )}
        </>
    );
};

const paddedBody = css`
    padding: 32px;
`;

const JobberSuccessText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
    height: 90%; //because of the footer
    width: 100%;
`;
export default OrganizationSettingsTabs;
