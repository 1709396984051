import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import Button from '../../../components/general/Button';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import { color_shades_dark, color_variants_shadow_10, dark_grey_blue_70 } from '../../../constants/colors';
import { getApplicationsForOrg } from '../../../api/controllers/applications';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import StyledDropdown from '../../applicationSettings/components/customAssistantConfigurations/StyledDropdown';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../../constants/breakpoints';
import { getSparkMainBaseUrl } from '../../../constants/Urls';
import { getJobberClientId } from '../../../constants/integrationClientIds';

const OrganizationIntegrationsSettings: React.FC<{ organizationId: string }> = ({ organizationId }) => {
    const [showJobberModal, setShowJobberModal] = useState(false);
    const [showSalesforceModal, setShowSalesforceModal] = useState(false);
    const [salesforceCredentials, setSalesforceCredentials] = useState<{ clientId: string, clientSecret: string }>({
        clientId: '',
        clientSecret: ''
    });

    const [selectedLocation, setSelectedLocation] = useState<{ jobber: string, salesforce: string }>({ jobber: '', salesforce: '' });
    const [applications, setApplications] = useState<ApplicationModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchApplications = async () => {
            setIsLoading(true);
            try {
                const result = await getApplicationsForOrg(organizationId);
                if (result.resultType === "Ok" && result.data) {
                    setApplications(result.data);
                }
            } catch (error) {
                console.error('Error fetching applications:', error);
            }
            setIsLoading(false);
        };

        if (organizationId) {
            fetchApplications();
        }
    }, [organizationId]);

    const getRedirectUri = () => {
        return `${getSparkMainBaseUrl()}/v/orgSettings`;
    }

    const handleJobberConnect = () => {
        const redirectUri = getRedirectUri();
        const clientId = getJobberClientId();
        window.location.href = `https://api.getjobber.com/api/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=Jobber-${selectedLocation.jobber}`;
    };

    const handleSalesforceConnect = () => {
        const redirectUri = getRedirectUri();
        const clientId = salesforceCredentials.clientId;
        window.location.href = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=full api refresh_token&state=Salesforce-${selectedLocation.salesforce}-id-${clientId}-secret-${salesforceCredentials.clientSecret}`;
    };

    const locationOptions = applications.map(app => ({
        value: app.id,
        label: app.name
    }));

    return (
        <>
            <div className={integrationsSectionStyle}>
                {isLoading && <span className={loadingStyle}>Loading applications...</span>}
                <div className={integrationCardStyle}>
                    <div className={integrationCardContentStyle}>
                        <h2>Jobber Integration</h2>
                        <p>Jobber is an award-winning business management platform for small home service businesses.</p>
                        <p>Connect to Jobber to give your assistant access to client and customer information.</p>
                        <div className={dropdownContainerStyle}>
                            <CustomDropdown
                                disabled={isLoading}
                                handleOnChange={(_, value) => setSelectedLocation({ ...selectedLocation, jobber: value as string })}
                                options={locationOptions}
                                value={selectedLocation.jobber}
                                placeholder="Select a location"
                                size="medium"
                            />
                        </div>
                    </div>
                    <Button
                        themes={["primary"]}
                        text="Authenticate"
                        className={authenticateButtonStyle(selectedLocation.jobber === '')}
                        onClick={() => selectedLocation.jobber && setShowJobberModal(true)}
                    />
                </div>

                <div className={integrationCardStyle}>
                    <div className={integrationCardContentStyle}>
                        <h2>Salesforce Integration</h2>
                        <p>Salesforce is a cloud-based customer relationship management (CRM) platform that helps businesses manage customer interactions, sales, marketing, and support.</p>
                        <p>Connect to Salesforce to give your assistant access to client and customer information.</p>
                        <div className={dropdownContainerStyle}>
                            <CustomDropdown
                                disabled={isLoading}
                                handleOnChange={(_, value) => setSelectedLocation({ ...selectedLocation, salesforce: value as string })}
                                options={locationOptions}
                                value={selectedLocation.salesforce}
                                placeholder="Select a location"
                                size="medium"
                            />
                        </div>
                        <div className={credentialsContainerStyle}>
                            <input
                                type="text"
                                placeholder="Client ID"
                                value={salesforceCredentials.clientId}
                                onChange={(e) => setSalesforceCredentials(prev => ({ ...prev, clientId: e.target.value }))}
                                className={inputStyle}
                            />
                            <input
                                type="password"
                                placeholder="Client Secret"
                                value={salesforceCredentials.clientSecret}
                                onChange={(e) => setSalesforceCredentials(prev => ({ ...prev, clientSecret: e.target.value }))}
                                className={inputStyle}
                            />
                        </div>
                    </div>
                    <Button
                        themes={["primary"]}
                        text="Authenticate"
                        className={authenticateButtonStyle(selectedLocation.salesforce === '' || salesforceCredentials.clientId === '' || salesforceCredentials.clientSecret === '')}
                        onClick={() => selectedLocation.salesforce && setShowSalesforceModal(true)}
                    />
                </div>
            </div>

            {showJobberModal && (
                <ConfirmationDialog
                    title="Connect to Jobber"
                    onClose={() => setShowJobberModal(false)}
                    onConfirm={handleJobberConnect}
                    deleteText="Authenticate"
                >
                    <WarningConfirmation
                        text="By authenticating to Jobber, you grant access to your client and customer information. This integration helps provide better service through voice interactions."
                    />
                </ConfirmationDialog>
            )}

            {showSalesforceModal && (
                <ConfirmationDialog
                    title="Connect to Salesforce"
                    onClose={() => setShowSalesforceModal(false)}
                    onConfirm={handleSalesforceConnect}
                    deleteText="Authenticate"
                >
                    <WarningConfirmation
                        text="By authenticating to Salesforce, you grant access to your client and customer information. This integration helps provide better service through voice interactions."
                    />
                </ConfirmationDialog>
            )}
        </>
    );
};

const integrationsSectionStyle = css`
    padding: 0px 24px;
    height: 100%;
    ${breakpoint_small} {
        margin-top: 5px;
        padding: 0px;
    }
`;

const integrationCardStyle = css`
    border: 1px solid ${color_shades_dark};
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    border-radius: 8px;
    padding: 24px;
    background: white;
    box-shadow: 0 2px 4px 0 ${color_variants_shadow_10};

    h2 {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
    }
    
    p {
        margin-bottom: 6px;
        font-size: 14px;
        color: ${dark_grey_blue_70};
    }

    ${breakpoint_small} {
        margin-top: 5px;
        display: block;
    }
`;

const integrationCardContentStyle = css`
    width: 50%;
    ${breakpoint_small} {
        width: 100%;
    }
`;

const dropdownContainerStyle = css`
    margin-top: 16px;
`;

const loadingStyle = css`
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: ${color_shades_dark};
`;

const authenticateButtonStyle = (disabled: boolean) => css`
    align-self: center;
    ${disabled && css`
        opacity: 0.5;
        cursor: not-allowed;
    `}
`;

const credentialsContainerStyle = css`
    padding: 4px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const inputStyle = css`
    padding: 8px 12px;
    border: 1px solid ${color_shades_dark};
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    
    &:focus {
        outline: none;
        border-color: #0066cc;
    }
`;

const CustomDropdown = styled(StyledDropdown)`
    padding: 5px;
    margin: 0 0 24px 0;
    
    .Select-menu-outer {
        max-height: none !important;
        z-index: 10001;
        padding: 10px 0px;
    }
    
    .Select-menu {
        max-height: 300px;
    }
`;

export default OrganizationIntegrationsSettings;