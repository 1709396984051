import React from 'react'
import styled from '@emotion/styled'
import { color_shades_dark } from '../../../constants/colors'
import MenuContainer from '../../../hooks/MenuContainer'
import CheckboxField from '../../../components/forms/CheckboxField'
import TextField from '../../../components/forms/TextField'
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection'
import CollapsableItem from './CollapsableItem'
import ContentCard, { NoMarginContentCard } from '../../../components/structure/ContentCard'
import Scrollbars from 'react-custom-scrollbars'
import { MenuItemOption } from '../../../models/nlp/menu/MenuItemOption'
import { css } from '@emotion/css'
import PersistentPropertyTitle from './PersistentPropertyTitle'

const listImage = require("../../../content/images/menu-editor/list.svg");
const promptImage = require("../../../content/images/menu-editor/prompted-group.svg");
const settingsIcon = require('../../../content/images/tab-icons/settings-dark-icon.svg');
const deleteIcon = require('../../../content/images/content-explorer/delete.svg');

interface MenuItemOptionGroupEditorProps {
    rightPanelRef: React.RefObject<Scrollbars>
}

export const getOptionName = (option: MenuItemOption) => {
    if (option?.colloquialName?.length) {
        if (option?.colloquialName.toLowerCase() !== option?.name.toLowerCase()) {
            return `${option.name} ("${option.colloquialName}")`;
        }
    }
    return option.name;
}

export default function MenuItemOptionGroupEditor(props: MenuItemOptionGroupEditorProps) {
    const menuContainer = MenuContainer.useContainer();
    const lastItem = menuContainer.path[menuContainer.path.length - 1]
    const advancedSettings = [
        {
            label: <PersistentPropertyTitle
                title="Option Group Referred to As"
                item={lastItem.selectedOptionGroup}
                propertyName='colloquialName'
            />,
            tooltip: `This is the name the assistant will use to refer to ${lastItem?.selectedOption?.name || 'this option group'} in prompts.`,
            placeholder: "Enter a new name or leave blank to use the option group's original name",
            value: lastItem?.selectedOptionGroup?.colloquialName,
            onChange: (e) => {
                lastItem.selectedOptionGroup.colloquialName = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "colloquialName");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Full Prompt"
                item={lastItem.selectedOptionGroup}
                propertyName='initialPromptOverride'
            />,
            tooltip: "This prompt makes reference to the menu item by name.",
            placeholder: "Enter a question",
            value: lastItem?.selectedOptionGroup?.initialPromptOverride,
            onChange: (e) => {
                lastItem.selectedOptionGroup.initialPromptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "initialPromptOverride");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Simplified Prompt"
                item={lastItem.selectedOptionGroup}
                propertyName="simpleInitialPromptOverride"
            />,
            tooltip: "This prompt simply asks the customer to make a selection without referencing the menu item name.",
            placeholder: "Enter a question",
            value: lastItem?.selectedOptionGroup?.simpleInitialPromptOverride,
            onChange: (e) => {
                lastItem.selectedOptionGroup.simpleInitialPromptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "simpleInitialPromptOverride");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Multi-Item Order Prompt"
                item={lastItem.selectedOptionGroup}
                propertyName='numberedInitialPromptOverride'
            />,
            tooltip: "This prompt asks the customer for selections when ordering multiple items of the same type, one at a time.",
            placeholder: "Enter a question",
            value: lastItem?.selectedOptionGroup?.numberedInitialPromptOverride,
            onChange: (e) => {
                lastItem.selectedOptionGroup.numberedInitialPromptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "numberedInitialPromptOverride");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Follow-Up Prompt"
                item={lastItem.selectedOptionGroup}
                propertyName='promptOverride'
            />,
            tooltip: "This prompt is used if the customer doesn't respond to the initial prompt.",
            placeholder: "Enter a question",
            value: lastItem?.selectedOptionGroup?.promptOverride,
            onChange: (e) => {
                lastItem.selectedOptionGroup.promptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "promptOverride");
            }
        }
    ]
    const activeOptions = lastItem?.selectedOptionGroup?.options?.filter(option => !option.isDisabled) ?? [];
    const inactiveOptions = lastItem?.selectedOptionGroup?.options?.filter(option => option.isDisabled) ?? [];

    function renderOptions(options: MenuItemOption[]) {
        return options.map((option, index) => {
            return (
                <OptionWrapper
                    key={`option.${index}`}
                    onClick={() => {
                        props.rightPanelRef?.current?.scrollToTop();
                        menuContainer.path?.push({
                            text: option.name,
                            pathLength: menuContainer.path?.length ?? 0,
                            selectedOption: option,
                            selectedOptionGroup: null,
                            selectedSizeGroup: null,
                            selectedSize: null,
                        });
                        menuContainer.setPath([...menuContainer.path])
                    }}
                >
                    <ListItemLeftWrapper>
                        <CollapsableItem
                            text={getOptionName(option)}
                            isDisabled={option.isDisabled}
                            onEnabledChange={(enabled) => {
                                option.isDisabled = !enabled;
                                menuContainer.updateMenuItem(menuContainer.menuItem, option, "isDisabled");
                            }}
                        />
                        {
                            (option.parentOptionGroupReferenceId && option.parentOptionGroupReferenceId !== lastItem.selectedOptionGroup.referenceId)
                                ?
                                <DeleteIcon
                                    src={deleteIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const updatedOptions = lastItem.selectedOptionGroup.options.filter(o => o !== option);
                                        lastItem.selectedOptionGroup.options = updatedOptions;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "");
                                    }}
                                />
                                : <></>
                        }


                    </ListItemLeftWrapper>
                </OptionWrapper>
            )
        })
    }

    return (
        <>
            {lastItem?.selectedOptionGroup ?
                <>
                    <ContentCollapsingSection
                        title={"Customer Selection Settings"}
                        icon={promptImage}
                        isCollapsedByDefault={false}
                        className={firstSectionStyle}
                    >
                        <StyledContentCard
                            title='Edit Customer Selection Settings'
                            subtitle="If the customer hasn't chosen an option from this group and it's required, the assistant will prompt them to pick one — unless you toggle it to ask only if no selection is made."
                            id="askForSelection"

                        >
                            {(!lastItem.selectedOptionGroup?.numberOfRequiredOptions
                                || lastItem.selectedOptionGroup?.numberOfRequiredOptions === 0
                                || `${lastItem.selectedOptionGroup?.numberOfRequiredOptions}` === "0"
                            ) &&
                                <StyledCheckboxField
                                    checked={lastItem?.selectedOptionGroup.promptIfNoSelections ?? false}
                                    onChange={(e) => {
                                        lastItem.selectedOptionGroup.promptIfNoSelections = e.target.checked;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "promptIfNoSelections");
                                    }}
                                    label='Ask for a selection if none are made'
                                    labelExtension={
                                        <PersistentPropertyTitle
                                            item={lastItem.selectedOptionGroup}
                                            propertyName='promptIfNoSelections'
                                        />
                                    }
                                />
                            }
                            <TextField
                                useForm={false}
                                disabled={false}
                                value={lastItem.selectedOptionGroup.numberOfRequiredOptions}
                                label={
                                    <PersistentPropertyTitle
                                        title="Number of Required Customer Selections"
                                        item={lastItem.selectedOptionGroup}
                                        propertyName='numberOfRequiredOptions'
                                    />
                                }
                                tooltip='This is the number of selections the customer has to make. If none, enter 0.'
                                tooltipStyle={tooltipStyle}
                                placeholder="0"
                                onChange={(e) => {
                                    let num = parseInt(e.target.value);
                                    if (num < 0)
                                        num = 0;
                                    lastItem.selectedOptionGroup.numberOfRequiredOptions = num;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "numberOfRequiredOptions");
                                }}
                                type="number"
                            />

                            <TextField
                                useForm={false}
                                disabled={false}
                                value={lastItem.selectedOptionGroup.maxSelections ?? ""}
                                label={
                                    <PersistentPropertyTitle
                                        title="Maximum number of Customer Selections"
                                        item={lastItem.selectedOptionGroup}
                                        propertyName='maxSelections'
                                    />
                                }
                                tooltip='This is the maximum number of selections the customer can make. If none, leave empty'
                                tooltipStyle={tooltipStyle}
                                placeholder="NA"
                                onChange={(e) => {
                                    let num = parseInt(e.target.value);
                                    if (num < 0)
                                        num = 0;
                                    lastItem.selectedOptionGroup.maxSelections = num;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedOptionGroup, "maxSelections");
                                }}
                                type="number"
                            />
                        </StyledContentCard>
                    </ContentCollapsingSection>
                    <ContentCollapsingSection
                        title={"Options"}
                        isCollapsedByDefault={false}
                        icon={listImage}
                    >
                        {activeOptions.length > 0 &&
                            <ActiveOptionsContentCard
                                title={`Edit Active Options`}
                                subtitle='These options are currently active and available to customers.'
                            >
                                {renderOptions(activeOptions)}
                            </ActiveOptionsContentCard>
                        }
                        {inactiveOptions.length > 0 &&
                            <InactiveOptionsContentCard
                                title={`Edit Inactive Options`}
                                subtitle={`These options are currently inactive and unavailable to customers.`}
                            >
                                {renderOptions(inactiveOptions)}
                            </InactiveOptionsContentCard>
                        }
                    </ContentCollapsingSection>
                    <ContentCollapsingSection
                        title={"Advanced Settings (Admin Only)"}
                        icon={settingsIcon}
                        isCollapsedByDefault={true}
                        bottom
                        scrollDownOnClick={true}
                    >
                        <NoMarginContentCard
                            title='Edit Assistant Prompts'
                            subtitle="If any required menu choices haven't been made, the assistant will ask the customer one of these questions to complete them. Leave variables like {menuItem} and {itemPosition} alone as they will be replaced with contextually appropriate content."
                            id="optionGroupAdvancedSettings"
                        >
                            {advancedSettings?.map((setting, index) => {
                                return (
                                    <PropertyWrapper key={`setting.${index}`}>
                                        <TextField
                                            useForm={false}
                                            disabled={false}
                                            value={setting.value}
                                            label={setting.label}
                                            tooltip={setting.tooltip}
                                            placeholder={setting.placeholder}
                                            tooltipStyle={tooltipStyle}
                                            onChange={setting.onChange}
                                            type={typeof setting.value}
                                        />
                                    </PropertyWrapper>
                                )
                            })}
                        </NoMarginContentCard>
                    </ContentCollapsingSection>
                </>
                :
                <h6></h6>
            }
        </>
    )
};

const firstSectionStyle = css`
    border-top: none;
`;

const InactiveOptionsContentCard = styled(ContentCard)`
    margin-top: 32px;
    margin-bottom: 0;
`;

const ActiveOptionsContentCard = styled(ContentCard)`
    margin-bottom: 0;
`;

const tooltipStyle = css`
    margin-left: 8px;
`;

const PropertyWrapper = styled.div`
    display: flex;
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const OptionWrapper = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const StyledContentCard = styled(ContentCard)`
    margin: 0px;
    padding-bottom: 8px;
`;

const StyledCheckboxField = styled(CheckboxField)`
    margin-top: 24px;
`;

const DeleteIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;
